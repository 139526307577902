var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "form-div"
  }, [_c('div', {
    staticClass: "integral"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("我的金币：")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.$store.state.integralCurrency.gold))])]), _c('div', {
    staticClass: "input-box"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.gold,
      expression: "formData.gold"
    }],
    staticClass: "input",
    attrs: {
      "placeholder": "输入提取金币"
    },
    domProps: {
      "value": _vm.formData.gold
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "gold", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "all",
    on: {
      "click": function ($event) {
        _vm.formData.gold = _vm.$store.state.integralCurrency.gold;
      }
    }
  }, [_vm._v("全部提现")])])]), _c('van-button', {
    staticClass: "button",
    attrs: {
      "loading": _vm.buttonLoad,
      "color": "#C70C0C",
      "type": "danger",
      "block": ""
    },
    on: {
      "click": _vm.applyClick
    }
  }, [_vm._v("提交")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };