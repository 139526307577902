import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "core-js/modules/es.array.push.js";
export default {
  name: 'Index',
  components: {},
  data() {
    return {
      buttonLoad: false,
      formData: {
        gold: null
      }
    };
  },
  async created() {
    this.$store.dispatch('getIntegral');
  },
  async mounted() {},
  methods: {
    async applyClick() {
      let that = this;
      if (!this.formData.gold) {
        this.$toast({
          message: '请输入提取积分'
        });
        return false;
      }
      if (!this.$store.state.userInfo.bankNumber || this.$store.state.userInfo.bankNumber == '') {
        _Dialog.confirm({
          title: '提示',
          message: '需要先填写银行卡信息，才能提现',
          confirmButtonText: '去填写'
        }).then(() => {
          // on confirm
          that.$router.push('/integral/user');
        }).catch(() => {
          // on cancel
        });
        return false;
      }
      this.buttonLoad = true;
      this.formData.gold = Number(this.formData.gold);
      let result = await this.$request({
        method: 'post',
        url: '/app/api/my/gold/withdraw',
        data: this.formData
      });
      this.buttonLoad = false;
      if (result.code == 0) {
        this.$router.replace('/integral/success');
        this.$store.dispatch('getIntegralCurrency');
      } else if (result.code == 303) {} else {
        this.$toast({
          message: result.message
        });
      }
    }
  }
};